<template>
  <b-field
    :horizontal="horizontal"
    :label="label"
    :label-for="_uid.toString()"
    class="field-custom-date-picker"
  >
    <date-picker
      :value="date"
      :disabled-date="isDateDisabled"
      :input-attr="{ id: _uid.toString(), disabled, required }"
      :placeholder="placeholder"
      :range="range"
      format="DD-MM-YYYY"
      value-type="YYYY-MM-DD"
      @input="input"
    ></date-picker>
  </b-field>
</template>

<script>
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import DatePicker from "vue2-datepicker";

dayjs.extend(isBetween);

export default {
  components: { DatePicker },
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    range: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    maxToday: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
    };
  },
  computed: {
    minDate() {
      if (!this.min) {
        return dayjs().subtract(20, "year").toDate();
      }
      return dayjs(this.min, "YYYY-MM-DD").toDate();
    },
    maxDate() {
      if (!this.max) {
        return dayjs().add(20, "year").toDate();
      }
      return dayjs(this.max, "YYYY-MM-DD").toDate();
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.date = value;
      },
    },
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
    isDateDisabled(day) {
      if (!this.minDate && !this.maxDate) {
        return false;
      }

      day = dayjs(day);

      if (day.isBefore(this.minDate)) {
        return true;
      }

      if (this.maxToday) {
        return day.isAfter();
      }

      return day.isAfter(this.maxDate);
    },
  },
};
</script>
